<!--
   /**
      * api.vue
      * @module {src/modules/public}
      * @desc 前端api
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 5vw;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">系统框架</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">System framework</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_b_60 margin_t_60">
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/barracks01.jpg" style="width: 80%;"></p>
                      </el-col>
                      <el-col :span="24">
                        <p class="textP" style="font-size: 18px;line-height: 40px">结合客户的实际情况，微服务平台的概念模型定义如下：</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">1、注册中心：支持一个环境内所有域下所有微服务的注册</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">2、配置中心：支持支持一个环境内所有域下所有微服务的配置</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">3、APM：支持一个环境内所有域下所有微服务的APM监控</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">4、断路器监控中心：支持一个环境内所有域下所有微服务的断路器监控，支持按每个版本查看</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">5、日志中心：支持一个环境内所有域下所有微服务的日志收集、查看</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">6、域：对应完整的业务域，比如工单域</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">7、网关：网关分为外部网关和内部网关。外部网关部署在DMZ区，用于把API对外网暴露；内部网关用于跨系统间的API调用</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">8、系统：对应实际的业务系统，每个域有多个业务系统</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">9、应用：对应业务系统中的业务模块，每个业务系统有多个应用</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">10、微服务：每个应用有多个微服务</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">11、微服务版本：每个微服务可以有多个版本，其中可以有多个上线运行版本</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">12、API：每个微服务版本提供的API</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">13、实例：每个微服务版本的运行进程</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">框架特点</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">Characteristics of framework</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_b_60 margin_t_60">
                      <el-col :span="24">
                        <p class="textP" style="font-size: 18px;line-height: 40px">1、使用Ngnix为web服务. 处理高性能和高效率的并发一直是使用Nginx的主要优势</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">2、使用Nameko微服务框架，简单，轻量，高效</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">3、使用Uvloop为核心引擎，使微服务在很多情况下单机并发甚至不亚于Golang</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">4、使用RabbitMQ作为同步变异步、解耦、控制其他程序的任务处理量</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">5、使用pymongodb为数据库驱动，进行数据库连接</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">6、使用aiohttp为Client，对其他微服务进行访问。</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">7、使用opentracing为分布式追踪系统。</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">8、使用unittest做单元测试，并且使用mock来避免访问其他微服务。</p>
                        <p class="textP" style="font-size: 18px;line-height: 40px">9、使用swagger做API标准，能自动生成API文档。</p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 20px 2.5%;">
            <el-row :gutter="10" class="margin_b_10 margin_t_10">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="margin-bottom: 10px;">
                <el-row :gutter="10" class="boxShadow_no_border">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row :gutter="10" class="margin_b_40">
                      <el-col :span="24">
                        <p class="bigTitle">系统运行视图</p>
                        <p class="lineTitle"></p>
                        <p class="enTitle">System Run view</p>
                      </el-col>

                    </el-row>
                    <el-row :gutter="30" class="margin_b_10 margin_t_10">
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/api04.png" style="width: 80%;"></p>
                      </el-col>
                      <el-col :span="24">
                        <p class="textP" style="font-size: 18px;line-height: 40px">在运行期，作为一个微服务架构的平台与业务系统，除了业务应用本身外，还需要有接入服务、统一门户、基础服务等平台级服务来保障业务系统的可靠运行。图中的公共服务就是业务处理过程中需要用到的一些可选服务。</p>
                      </el-col>
                      <el-col :span="24" style="padding: 40px; margin-bottom: 20px">
                        <p style="text-align: center"><img src="@/assets/api05.png" style="width: 80%;"></p>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
export default {
  name: 'Login',
  components: {},
  data () {
    return {
    }
  },
  mounted () {

  },
  watch: {

  },
  methods: {

  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #fff !important;
}
</style>
